<template>
  <div class="justify-content-start view-container">
    <HeaderResidential />
  </div>
  <div class="justify-content-start w-100 view-container">
    <div
      v-if="!loading"
      style="width: 100%; height: 80vh; display: flex;
      align-items:center; flex-direction:column">
      <div
        v-if="space.name && getSpaceThatIsBeingManaged && residential"
        style="width: 100%; display: flex; align-items: center" >
          <i
            :class="`${hexIcon} icon general-blue-color`"
            style="font-size: 32px; margin-right: 12px;"
          ></i>
        <h1 style="margin-top: 0px;">{{space.name}}</h1>
      </div>

      <div
        v-if="space.name && getSpaceThatIsBeingManaged && residential && currentAnalytics"
        style="display: flex; justify-content: center; flex-direction: column; align-items: center; width: 100%; margin-top: 17px;"
      >
        <div class="flex" style="display: flex; width: 100%; flex-wrap: wrap;">
          <div class="databox">
            <p style="font-size: 48px;" class="mt-0">{{ currentAnalytics.lastWeekReservations }}</p>
            <p style="font-size: 12px; line-height: 14px;" class="mt-0">{{ $t('areas.last7Days') }}</p>
          </div>
          <div class="databox">
            <p style="font-size: 48px;" class="mt-0">{{ currentAnalytics.lastMonthReservations }}</p>
            <p style="font-size: 12px; line-height: 14px;" class="mt-0">{{ $t('areas.last30Days') }}</p>
          </div>
          <div class="databox">
            <p style="font-size: 24px;" class="mt-0">{{ currentAnalytics.mostReservedHour }}</p>
            <p style="font-size: 12px; line-height: 14px;" class="mt-0">{{ $t('areas.mostReservatedHour') }}</p>
          </div>
          <div class="databox">
            <p style="font-size: 24px;" class="mt-0">{{ currentAnalytics.lessReservedHour }}</p>
            <p style="font-size: 12px; line-height: 14px;" class="mt-0">{{ $t('areas.lessReservatedHour') }}</p>
          </div>
          <router-link :to="{
              path: '/digitalLibrary'
            }"
            @click.prevent="navigateToSpaceDLSection(space)"
          >
            <div class="databox" style="cursor: pointer;">
              <i class="icon large download" style="font-size: 32px;"></i>
              <p style="font-size: 16px; line-height: 18px;" class="mt-0">{{ $t('areas.digitalLibrary') }}</p>
            </div>
          </router-link>

        </div>
        <!-- <h2>Próximamente aquí podrás conocer estadísticas y gestionar más información de este espacio</h2> -->
        <div
          class="bell-curve-chart"
          style="display: flex; justify-content: center; transform: scale(1.5);
          margin-top: 30px; margin-left: -15px">
        </div>
      </div>
      <!--<div v-else>
        <h1>No hay espacios seleccionados, por favor selecciona uno</h1>
      </div>-->
    </div>
    <div v-else style="padding-top: 125px">
      <div  style="display: flex; width: 100%; justify-content: center" class="ui active blue massive centered inline text loader ">
        <h2>{{$t('loading')}}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderResidential from '@/components/ui/HeaderResidential'

export default {
  components: {
    HeaderResidential
  },
  data () {
    return {
      hexIcon: '',
      loading: false
    }
  },
  computed: {

    residential () {
      return this.$store.getters['residential/activeResidential']
    },

    currentAnalytics () {
      return this.$store.getters['socialAreas/currentAnalytics']
    },

    ...mapGetters('spacesManagement', ['getSpaceThatIsBeingManaged']),

    ...mapGetters('types', ['allAreasTypes', 'getIconByName']),

    space () {
      return {
        name: this.$t(`${this.getSpaceThatIsBeingManaged.title}`)
      }
    }
  },
  mounted () {
    if (!this.allAreasTypes.length || !this.residential.residentialId) {
      this.$router.push({ path: '/' })
    } else {
      this.setHexIcon()
    }
  },
  beforeUpdate () {
    if (!this.allAreasTypes.length || !this.residential.residentialId) {
      this.$router.push({ path: '/' })
    }
    this.setHexIcon()
  },

  methods: {

    navigateToSpaceDLSection (space) {
      // console.log('navigateToSpaceDLSection', space.name)
      this.$store.dispatch('digitalLibrary/setRouteToNavigate', space.name)
    },

    setHexIcon () {
      this.hexIcon = this.getIconByName(this.space.name)
    },

    ...mapActions('types', [
      'loadTypes'
    ]),

    ...mapActions('socialAreas', [
      'load_Areas'
    ]),

    loadAreas () {
      return this.load_Areas(this.residential.residentialId)
    }
  }
}
</script>

<style scoped>
/* Bell curve styles */

.percentile {
  width: 300px;
}
.percentile path {
  stroke: #374663;
  stroke-width: 1px;
}
.percentile circle {
  stroke: #374663;
  stroke-width: 1px;
  fill: white;
}
.percentile .interval {
  stroke: none;
}
.percentile #stripes path {
  stroke: #C9D3E2;
}

/* hex styles */
.hex {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: #2186b0;
  border-color: #2186b0;
  position: absolute;
  display: inline-block;
}
.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
  border-start-start-radius: 5px;
}
.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}

.hexagon {
  top: 30vh;
  left: 40%;
  position: absolute;
  margin: 0 auto;
  background-color: dodgerblue;
  border-radius: 10px;
  width: 100px;
  height: 63px;
  box-sizing: border-box;
  transition: all 1s;
  border: 0.4vh solid transparent;
}

/* Creating pseudo-class */
.hexagon:before, .hexagon:after {
    content: "";
    border: inherit;
    position: absolute;
    top: -0.5vh;
    left: -0.5vh;
    background-color: dodgerblue;
    border-radius: inherit;
    height: 100%;
    width: 100%;
}

/* Align them in such a way
that they form a hexagon */
.hexagon:before {
    transform: rotate(60deg);
}
.hexagon:after {
    transform: rotate(-60deg);
}

.databox {
  margin-bottom: 15px;
  border: 2px solid #008CC3;
  border-radius: 12px;
  height: 90px;
  width: 155px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* background-color: red; */
  color: #008CC3;
  padding: 10px 15px;
}

.databox-link-container {
  width: 100%;
}

.databox > p {
  margin: 0 !important;
  line-height: 100%;
}

</style>
